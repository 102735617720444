import React, {useState, useEffect} from 'react';

import './CompanyJoin.scss';

import {Link} from "react-router-dom";
import useAPI from "../../lib/hooks/useAPI";
import ActionButton from "../common/ActionButton";

import FlatBtn from "../common/FlatBtn";

import {ReactComponent as ImgCard} from "../common/img/card.svg";
import {ReactComponent as ImgSuccess} from "../common/img/success_circle.svg";
import {getQueryParams} from "../../lib/helpers/params";
import {useTranslation} from "react-i18next";

export default function CompanyJoin() {
    React.useEffect(() => {
        console.log('render CompanyJoin');
        return () => console.log('unmount CompanyJoin');
    });

    const api = useAPI();

    const [companyData, setCompanyData] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const {t} = useTranslation();

    const queryParams = getQueryParams();

    const getCompany = async () => {
        const res = await api.get("api/v1/tips.getCompany", {companyNumber: queryParams["c"]})
        if (!res.success || !res.data.company) {
            return
        }
        setCompanyData(res.data.company)
    }

    const approveEmployee = async () => {
        const res = await api.post("api/v1/company.approveEmployee", {hash: queryParams["hash"]})
        if (!res.success) {
            return
        }
        setShowSuccess(true)
    }

    useEffect(() => {
        getCompany()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="admin central">
            <div className="app-container admin__app-container">
                <div className="admin__header">
                    <Link to="/"><FlatBtn type="prev"/></Link>
                    <p className="header-text">{t("addCard")}</p>
                </div>
                {companyData && <>
                    <div className="admin__center justify-content-center align-items-center">
                        {!showSuccess && <p> {`${t("company")} "${companyData.name}"`} <br/> {t("invitesYouToBecomeACoinboxMember")}</p>}

                        {showSuccess &&
                        <div className="circle-success">
                            <ImgSuccess/>
                            <p className="circle-success__text success">{`${t("success")}!`}</p>
                        </div>
                        }
                    </div>
                    <div className="admin__footer">
                        {!showSuccess && <ActionButton image={<ImgCard/>} text={t("join")}
                                                       onClick={approveEmployee}/>}
                    </div>
                </>
                }
            </div>
        </div>
    );
}