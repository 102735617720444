import React, {useState} from 'react';

import './AccountEdit.scss';

import useApi from "../../../lib/hooks/useAPI";

import FlatBtn from "../../common/FlatBtn";
import ActionButton from "../../common/ActionButton";

import {ImgInput} from "../../common/ImgInput";
import {ReactComponent as ImgSuccess} from "../../common/img/success_circle.svg";
import {ReactComponent as ImgProfile} from "../../common/img/profile.svg";
import {useTranslation} from "react-i18next";

export default function AccountEdit({user, onClose}) {

  const api = useApi();

  const [name, setName] = useState(user.name);
  const [surname, setSurname] = useState(user.surname);
  const [accountNumber, setAccountNumber] = useState(user.accountNumber);
  const [BIN, setBIN] = useState(user.BIN);
  const [bankCode, setBankCode] = useState(user.bankCode);

  const [showSuccess, setShowSuccess] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);

  const {t} = useTranslation();

  const userUpdate = async () => {
    setSaveLoader(true);
    const res = await api.post("api/v1/user.update", {
      userId: user.id,
      name: name,
      surname: surname,
      accountNumber: accountNumber,
      bin: BIN,
      bankCode: bankCode,

    });
    setSaveLoader(false)
    if (!res.success) {
      return;
    }

    setShowSuccess(true);
    setTimeout(() => window.location.reload(), 1000);
  }

  const accountWithdraw = async () => {
    setSaveLoader(true);
    const res = await api.post("api/v1/account.withdraw", {userId: user.id});
    setSaveLoader(false)
    if (!res.success) {
      return;
    }

    setShowSuccess(true);
    setTimeout(() => window.location.reload(), 1000);
  }

  return (<div className="admin central noselect modal-padding">
    <div className="app-container admin__app-container">
      <div className="admin__header">
        <FlatBtn type="prev" onClick={() => onClose()}/>
        <p className="header-text">{t("editCompanyData")}</p>
      </div>
      <div className="admin__center">
        {!showSuccess && <div className="profile-edit">
          <div className="profile-edit__item">
            <ImgInput placeholder={t("accountName")} field={name} onChange={e => setName(e.target.value)} image={<ImgProfile/>}/>
          </div>
          <div className="profile-edit__item">
            <ImgInput placeholder={t("accountOfficialName")} field={surname} onChange={e => setSurname(e.target.value)} image={<ImgProfile/>}/>
          </div>
          <div className="profile-edit__item">
            <ImgInput placeholder={t("accountNumber")} field={accountNumber} onChange={e => setAccountNumber(e.target.value)} image={<ImgProfile/>}/>
          </div>
          <div className="profile-edit__item">
            <ImgInput placeholder={t("BIN")} field={BIN} onChange={e => setBIN(e.target.value)} image={<ImgProfile/>}/>
          </div>
          <div className="profile-edit__item">
            <ImgInput placeholder={t("bankCode")} field={bankCode} onChange={e => setBankCode(e.target.value)} image={<ImgProfile/>}/>
          </div>
          {/*<div className="profile-edit__item">*/}
          {/*  {!showSuccess && <ActionButton onClick={(e) => accountWithdraw(e)} text={t("withdraw") + " " + user.balance + " ₸"} loader={saveLoader}/>}*/}
          {/*</div>*/}
        </div>}
        {showSuccess && <div className="circle-success flex-grow-1">
          <ImgSuccess/>
          <p className="circle-success__text success">{t("dataUpdated")}</p>
        </div>}
      </div>
      <div className="admin__footer">
        {!showSuccess && <ActionButton onClick={(e) => userUpdate(e)} text={t("save")} loader={saveLoader}/>}
      </div>
    </div>
  </div>);
}
