import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";

import "./EmployeeList.scss";

import useApi from "../../lib/hooks/useAPI";
import EmployeeAdd from "./EmployeeAdd";
import FlatBtn from "../common/FlatBtn";

import { ReactComponent as ImgUsersGroup } from "../common/img/users_group.svg";
import Loader from "../common/Loader";
import useNotify from "../../lib/hooks/Notify";
import Profile from "./Profile";
import { useTranslation } from "react-i18next";
import { List } from "../common/List";

export const EmployeeList = () => {
  const api = useApi();

  const notify = useNotify();

  const { companyId } = useParams();

  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddEmployee, setShowAddEmployee] = useState(false);

  const [userId, setUserId] = useState("");
  const [showUserProfile, setShowUserProfile] = useState(false);

  const [deleteLoader, setDeleteLoader] = useState("");
  // const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    getEmployees();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getEmployees = useCallback(async () => {
    setLoading(true);
    const res = await api.get("api/v1/user.get", {
      companyId: companyId,
    });
    if (!res.success || !res.data.users) {
      return;
    }
    if (res.data.users.length < 30) {
      setHasMore(false);
    }
    setEmployees(res.data.users);
    setLoading(false);
    // if (p === 0) {
    //   setEmployees(res.data.users);
    //   setLoading(false);
    // } else {
    //   setEmployees((pv) => {
    //     return getUniqueArray([...pv, ...res.data.users]);
    //   });
    //   setLoading(false);
    // }
  }, [api, companyId]);

  const companyDeleteEmployee = async (phone) => {
    if (!window.confirm(t("removeFromTheCompany"))) {
      return;
    }

    setDeleteLoader(phone);
    const res = await api.post("api/v1/company.deleteEmployee", {
      phone: phone,
      companyId: companyId,
    });
    setDeleteLoader("");
    if (!res.success) {
      return;
    }

    notify({ type: "success", title: t("employeeRemoved"), description: "ok" });
    getEmployees();
  };

  // const scrollHandler = useCallback(
  //   (e) => {
  //     const heightCheck =
  //       e.target.scrollHeight - e.target.scrollTop - window.innerHeight <= 0;
  //     if (!loading && heightCheck) {
  //       setPage((pv) => {
  //         getEmployees(pv + 1);
  //         return pv + 1;
  //       });
  //     }
  //   },
  //   [getEmployees, loading]
  // );

  // const optimizedFn = useCallback(debounce(scrollHandler), []);

  return (
    <div className="admin central noselect">
      <div
        className="app-container admin__app-container"
        id={"employeesListID"}
      >
        <div className="admin__header">
          <Link to={"/company/" + companyId}>
            <FlatBtn type="prev" />
          </Link>
          <p className="header-text">
            {`${t("employees")} ${
              employees && employees.length > 0 ? employees.length : ""
            } ${employees && employees.length > 0 ? t("per") : ""}`}
          </p>
          <FlatBtn type="plus" onClick={() => setShowAddEmployee(true)} />
        </div>
        <div className="admin__center">
          {loading && employees.length === 0 && (
            <div className="d-flex flex-column justify-content-center flex-grow-1">
              <Loader type="dot" />
            </div>
          )}
          {!loading && employees.length === 0 && (
            <div className="employee-list__empty">
              <div className="employee-list__users-empty-img">
                <ImgUsersGroup />
              </div>
              <p className="employee-list__users-empty-text">
                {t("youDontHaveEmployeesYet")} <br />{" "}
                {t("youCanAddNewEmployee")}
              </p>
            </div>
          )}
          {employees.length > 0 && (
            <div className="employee-list__items">
              <List
                list={employees}
                type={"employees"}
                id={"employeesListID"}
                scrollHandler={() => console.log()}
                renderCondition={employees.length > 0}
                listItemProps={{
                  onClickItem: (id) => {
                    setUserId(id);
                    setShowUserProfile(true);
                  },
                  onClickRightIcon: (phone) => companyDeleteEmployee(phone),
                  deleteLoader: (phone) => deleteLoader !== phone,
                }}
                hasMore={hasMore}
              />
            </div>
          )}
          {/* {loading && employees.length > 0 && hasMore && <Loader type="dot" />} */}
        </div>
      </div>
      <Modal
        portalClassName="left-slide-window"
        isOpen={showAddEmployee}
        contentLabel="EmployeeAdd"
        closeTimeoutMS={300}
        className="admin-modal"
        overlayClassName="admin-modal__overlay"
      >
        <EmployeeAdd onClose={() => setShowAddEmployee(false)} />
      </Modal>
      <Modal
        portalClassName="left-slide-window"
        isOpen={showUserProfile}
        contentLabel="Profile"
        closeTimeoutMS={300}
        className="admin-modal"
        overlayClassName="admin-modal__overlay"
      >
        <Profile userId={userId} onClose={() => setShowUserProfile(false)} />
      </Modal>
    </div>
  );
};
