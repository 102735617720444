import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import Modal from 'react-modal'

import './CompanyAccounts.scss'

import useApi from '../../../lib/hooks/useAPI'
import FlatBtn from '../../common/FlatBtn'

import Loader from '../../common/Loader'
import useNotify from '../../../lib/hooks/Notify'
import {useTranslation} from 'react-i18next'
import ActionButton from '../../common/ActionButton'
import AccountEdit from "./AccountEdit";
import {AccountItem} from "./AccountItem";

export const CompanyAccounts = () => {
  const api = useApi()

  const notify = useNotify()

  const {companyId} = useParams()

  const [accounts, setAccounts] = useState([])
  const [user, setUser] = useState(0)

  const [loading, setLoading] = useState(true)
  const [showAccount, setShowAccount] = useState(false)

  const {t} = useTranslation()

  const getAccounts = async () => {
    setLoading(true)
    const res = await api.get('api/v1/company.getAccounts', {companyId: companyId})
    if (!res.success || !res.data.accounts) {
      return
    }
    setAccounts(res.data.accounts)
    setLoading(false)
  }

  const onAddAccountClick = async () => {
    setLoading(true)
    const res = await api.post('api/v1/company.createAccount', {companyId: companyId})
    if (!res.success) {
      return
    }
    setLoading(false)
    await getAccounts()
  }

  useEffect(() => {
    getAccounts()
  }, [])

  return (<div className="admin central noselect">
    <div className="app-container admin__app-container">
      <div className="admin__header">
        <Link to={'/company/' + companyId}>
          <FlatBtn type="prev"/>
        </Link>
        <p className="header-text">
          {`${t('companyAccounts')}`}
        </p>
      </div>
      <div className="admin__center">
        {loading && (<div className="d-flex flex-column justify-content-center flex-grow-1">
          <Loader type="dot"/>
        </div>)}
        {!loading && accounts.length === 0 && (<div className="employee-list__empty">
          <ActionButton onClick={() => {onAddAccountClick() }} text="Создать счет"/>
        </div>)}
        {!loading && accounts.length > 0 && (<div className="employee-list__items">
          {accounts.map((emp) => <AccountItem key={emp.id} emp={emp} onClickItem={() => {
            setUser(emp)
            setShowAccount(true)
          }}
          />)}
        </div>)}
      </div>
    </div>
    <Modal portalClassName="left-slide-window"
      isOpen={showAccount}
      contentLabel="Profile"
      closeTimeoutMS={300}
      className="admin-modal"
      overlayClassName="admin-modal__overlay">
      <AccountEdit user={user} onClose={() => setShowAccount(false)}/>
    </Modal>
  </div>)
}
