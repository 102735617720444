import React, { useCallback, useEffect, useState, useRef } from "react";
import "./Admin.scss";

import useApi from "../../lib/hooks/useAPI";
import FlatBtn from "../common/FlatBtn";

import Modal from "react-modal";
import LeftMenu from "./LeftMenu";
import { getNamedMonth } from "../../lib/helpers/time";

import Loader from "../common/Loader";
import { useTranslation } from "react-i18next";
import { List } from "../common/List";
import { getTransactions } from "../../Services/Utils/getTransactions";
import { LoadMore } from "../common/LoadMore";


export default function Admin({ onClose }) {
  const api = useApi();

  const currentDate = new Date()

  const [showMenu, setShowMenu] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [loadTransactions, setLoadTransactions] = useState(false);
  const [lines, setLines] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [date, setDate] = useState(currentDate)
  const [monthSum, setMonthSum] = useState(0)
  const [monthList, setMonthList] = useState([])
  const [isMonthList, setIsMonthList] = useState(false)
  const popupRef = useRef(null)

  const { t } = useTranslation();
  const monthNames = t("monthList", { returnObjects: true })

  const generateMonthList = useCallback(() => {
    const arrDate = Array(6).fill().map((_, i) => {
      const d = new Date()
      d.setMonth(d.getMonth() - i)
      return d
    })

    setMonthList(arrDate)
  }, [])

  useEffect(() => {
    getTransactions(
      api,
      "api/v1/transaction.get",
      { p: page, l: 30, m: date.getMonth() + 1, y: date.getFullYear() },
      setLoadTransactions,
      separateToLines,
      setTransactions,
      setHasMore,
      setMonthSum,
    );

    generateMonthList()

    const handlerClickOutside = (e) => {
      if (!e.composedPath().includes(popupRef.current)) {
        setIsMonthList(false)
      }
    }
    document.body.addEventListener("click", handlerClickOutside)

    return () => document.body.removeEventListener("click", handlerClickOutside)
  }, [date]); // eslint-disable-line react-hooks/exhaustive-deps



  // useEffect(() => {
  //   separateToLines(transactions);
  // }, [transactions])

  //   const dateFormat = (createdAt) => {
  //     const date = new Date(createdAt);
  //     return date.getHours() + ":" + date.getMinutes();
  //   };

  // const scrollHandler = (e) => {
  //   const heightCheck =
  //     e.target.scrollHeight - e.target.scrollTop - window.innerHeight <= 0;
  //   if (!loadTransactions && heightCheck) {
  //     setPage((pv) => {
  //       getTransactions(
  //         api,
  //         "transaction.get",
  //         { p: pv + 1, l: 30 },
  //         setLoadTransactions,
  //         separateToLines,
  //         setTransactions,
  //         setHasMore
  //       );
  //       return pv + 1;
  //     });
  //   }
  // };

  // const optimizedFn = useCallback(debounce(scrollHandler), []);

  const clickHandler = () =>
    setPage((pv) => {
      getTransactions(
        api,
        "api/v1/transaction.get",
        { p: pv + 1, l: 30, m: date.getMonth() + 1, y: date.getFullYear() },
        setLoadTransactions,
        separateToLines,
        setTransactions,
        setHasMore,
        setMonthSum,
      );
      return pv + 1;
    });

  const separateToLines = (transactions) => {
    const lines = {};
    let transactionDateLine;
    let lastID;
    transactions.forEach((t) => {
      const date = new Date(t.createdAt);
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      let day;

      // if (date.getDay() === today.getDay()) {
      //     day = "Сегодня";
      // } else if (date.getDay() === yesterday.getDay()) {
      //     day = "Вчера";
      // } else {
      day = date.getDate() + " " + getNamedMonth(date.getMonth());
      // }

      if (transactionDateLine !== day) {
        transactionDateLine = day;
        lastID = t.id;
        lines[lastID] = { day: day, sum: 0 };
        if (t.type === 1) {
          lines[lastID].sum = t.amountOnHand === 0 ? t.amount : t.amountOnHand;
        }
      } else {
        if (t.type === 1) {
          lines[lastID].sum += t.amountOnHand === 0 ? t.amount : t.amountOnHand;
        }
      }
    });

    setLines(lines);
  };

  return (
    <div className="admin central noselect modal-padding">
      <div className="app-container admin__app-container">
        <div className="admin__header">
          <FlatBtn type="burger" onClick={() => setShowMenu(true)} />
          <p className="header-text">{t("main")}</p>
          <FlatBtn type="bell" />
        </div>
        <div className="admin__popup" ref={popupRef}>
          <p onClick={() => setIsMonthList(true)}>{t("amountFor")} {monthNames[date.getMonth() + 1]}: <i className="totalsum">{monthSum}</i></p>
          {isMonthList && <ul>
            {monthList.map(d => (
              <li key={d} onClick={() => {
                setDate(d)
                setIsMonthList(false)
              }}>{monthNames[d.getMonth() + 1]}</li>
            ))}
          </ul>}
        </div>
        {loadTransactions && transactions.length === 0 && (
          <div className="d-flex flex-grow-1">
            <Loader type="dot" />
          </div>
        )}
        {!loadTransactions && transactions.length === 0 && (
          <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            {t("noReceipts")}
          </div>
        )}
        <div
          className="transaction-list admin__transaction-list"
          id={"startingPageListID"}
        >
          {transactions.length > 0 && lines && (
            <List
              type={"transactions"}
              list={transactions}
              id={"startingPageListID"}
              scrollHandler={() => console.log()}
              renderCondition={transactions.length > 0}
              listItemProps={{
                lines: lines,
                isAdmin: false,
              }}
              hasMore={hasMore}
            />
          )}
          {/* {loadTransactions && transactions.length > 0 && hasMore && (
            <Loader type="dot" />
          )} */}
          <LoadMore
            renderCondition={transactions.length > 0 && lines && hasMore}
            clickHandler={clickHandler}
            loadingCondition={loadTransactions}
            buttonText={t("loadMore")}
            fullListText={""}
          />
        </div>
      </div>
      <Modal
        portalClassName="left-slide-window"
        isOpen={showMenu}
        contentLabel="Menu"
        closeTimeoutMS={300}
        className="admin-modal"
        overlayClassName="admin-modal__overlay"
      >
        <LeftMenu onClose={() => setShowMenu(false)} />
      </Modal>
    </div>
  );
}
