import React, {useState, useEffect} from 'react';
import './Tips.scss';
import '../admin/Admin.scss';

import useApi from '../../lib/hooks/useAPI';

import {ReactComponent as ImgLogo} from "../common/img/logo_text_dark.svg";
import {ActionButtonBlack} from "../common/ActionButton";

export default function Apple() {

    const api = useApi();

    const [show, setShow] = useState(false)

    const ApplePay = async (e) => {

        const paymentRequest = {
            total: {
                label: 'CoinBox',
                amount: 100
            },
            countryCode: 'KZ',
            currencyCode: 'KZT',
            merchantCapabilities: ['supports3DS'],
            supportedNetworks: ['masterCard', 'visa']
        };

        const applePaySession = new window.ApplePaySession(1, paymentRequest)

        applePaySession.onvalidatemerchant = async (event) => {
            const res = await api.post("api/v1/apple.init", {url: event.validationURL})
            if (!res.success) {
                applePaySession.abort();
                return
            }
            applePaySession.completeMerchantValidation(res.data)
        }

        applePaySession.onpaymentauthorized = async (event) => {
            console.log("log2", event)

            const res = await api.post("api/v1/halyk.getToken", {
                tokenType: "page",
                amount: 100,
                userNumber: 1,
                currency: "KZT",
                orderSum: 10000,
                companyNumber: 2,
                companyName: "CoinBox Bar",
                tableNumber: "10",
                estimate: 5,
                comment: "",
                quickComment: [],
            });
            if (!res.success) {
                return;
            }

            await api.post("api/v1/apple.complete", {halykToken: res.data.token.access_token, orderNumber: res.data.invoiceId, appleToken: event.payment.token})
            applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS);
        }

        applePaySession.begin();
    }

    useEffect(() => {
        if (window.ApplePaySession) {
            setShow(true)
        }
    }, []) //

    return (
        <div className="tips central">
            <div className="app-container tips__app-container">
                <div className="tips__service-header">
                    <div className="tips__service-image"><ImgLogo width="120" height="30"/></div>
                </div>
                <div className="tips__panel">
                    <div className="tips__actions">
                        <div className="tips__action-item"><ActionButtonBlack onClick={() => ApplePay()}
                                                                              text="Apple"
                                                                              disabled={!show}/></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
