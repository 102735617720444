import React, {useContext, useState} from 'react';

import './Admin.scss';

import useApi from "../../lib/hooks/useAPI";
import FlatBtn from "../common/FlatBtn";

import {ReactComponent as ImgCard} from "../common/img/card.svg";
import {CardInput} from "../common/ImgInput";
import {AuthContext} from "../../lib/auth/Context";

import {ReactComponent as ImgSuccess} from "../common/img/success_circle.svg";
import ActionButton from "../common/ActionButton";
import {useTranslation} from "react-i18next";

export default function CardAdd({onClose}) {

    const api = useApi();

    const {state} = useContext(AuthContext);

    const [cardNumber, setCardNumber] = useState("");
    const [addLoader, setAddLoader] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);

    const {t} = useTranslation();

    const onCardNumberChange = val => {
        setCardNumber(val.replace(/\D/g, ""))
    }

    const addCardHandler = async () => {
        setAddLoader(true)
        const res = await api.post("api/v1/card.add", {userId: state.id, cardNumber: cardNumber});
        setAddLoader(false)
        if (!res.success) {
            return;
        }

        setShowSuccess(true);
        setTimeout(() => window.location.reload(), 500);
    }

    return (
        <div className="admin central noselect modal-padding">
            <div className="app-container admin__app-container">
                <div className="admin__header">
                    <FlatBtn type="prev" onClick={() => onClose()}/>
                </div>
                <div className="admin__center justify-content-center align-items-center">
                    {!showSuccess && <CardInput
                        image={<ImgCard/>}
                        placeholder={t("cardNumber")}
                        onChange={e => onCardNumberChange(e.target.value)}
                    />}
                    {showSuccess &&
                    <div className="circle-success">
                        <ImgSuccess/>
                        <p className="circle-success__text success">
                            {t("cardAdded")}
                        </p>
                    </div>
                    }
                </div>
                <div className="admin__footer">
                    {!showSuccess && <ActionButton
                        onClick={(e) => addCardHandler(e)}
                        text={t("add")}
                        disabled={cardNumber.length < 16}
                        loader={addLoader}
                    />}
                </div>
            </div>
        </div>
    );
}
