import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import useAPI from "../../lib/hooks/useAPI";
import FlatBtn from "../common/FlatBtn";

import { ReactComponent as ImgEye } from "../common/img/eye.svg";

import "./CompanyList.scss";
import Modal from "react-modal";
import LeftMenu from "./LeftMenu";
import Loader from "../common/Loader";
import CompanyCreate from "./CompanyCreate";
import { AuthContext } from "../../lib/auth/Context";
import config from "../../config";
import { useTranslation } from "react-i18next";
import { List } from "../common/List";
import { getUniqueArray } from "../../Services/Utils/getUniqueArray";
import { LoadMore } from "../common/LoadMore";
import { debounce } from "underscore";

export default function CompanyList() {
  const api = useAPI();

  const [companies, setCompanies] = useState([]);

  const { state } = useContext(AuthContext);

  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCompanyCreate, setShowCompanyCreate] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchValue, setSearchValue] = useState("")
  const [searchVisible, setSearchVisible] = useState(false)

  const searchRef = useRef(null)

  const { t } = useTranslation();

  useEffect(() => {
    getCompanies(page);

    const handlerClickOutside = (e) => {
      if (!e.composedPath().includes(searchRef.current)) {
        setSearchVisible(false)
        setSearchValue("")
      }
    }
    document.body.addEventListener("click", handlerClickOutside)

    return () => {
      document.body.removeEventListener("click", handlerClickOutside)
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true)
    setCompanies([])
    debounceGetCompanies(searchValue.trim())
  }, [searchValue])

  const getCompanies = useCallback(
    async (p, name="") => {
      setLoading(true);
      const res = await api.get("api/v1/company.get", { p: p, l: 30, name: name });
      if (!res.success || !res.data.companies) {
        return;
      }
      if (res.data.companies.length < 30) {
        setHasMore(false);
      }
      if (p === 0) {
        setCompanies(res.data.companies);
        setLoading(false);
      } else {
        setCompanies((pv) => getUniqueArray([...pv, ...res.data.companies]));
        setLoading(false);
      }
    },
    [api]
  );

  // const scrollHandler = useCallback(
  //   (e) => {
  //     const heightCheck =
  //       e.target.scrollHeight - e.target.scrollTop - window.innerHeight <= 0;
  //     if (!loading && heightCheck) {
  //       setPage((pv) => {
  //         getCompanies(pv + 1);
  //         setLoading(false);
  //         return pv + 1;
  //       });
  //     }
  //   },
  //   [getCompanies, loading]
  // );

  const clickHandler = () =>
    setPage((pv) => {
      getCompanies(pv + 1);
      return pv + 1;
    });
    
    const debounceGetCompanies = useCallback(debounce((name) =>getCompanies(page, name), 500), [])
    const onChangeSearch = (e) => {
      const {value} = e.target
      setSearchValue(value)
      setLoading(true)
      debounceGetCompanies(value.trim())
    }

  // const optimizedFn = useCallback(debounce(scrollHandler), []);

  return (
    <div className="admin central">
      <div
        className="app-container admin__app-container"
        id={"companiesListID"}
      >
        <div className="admin__header">
          <FlatBtn type="burger" onClick={() => setShowMenu(true)} />
          <div className="company__search" ref={searchRef} >
            <div className="d-flex"  onClick={() => setSearchVisible(true)}>
            <FlatBtn type="search"/>
            <p style={{marginLeft: 5}}>{t("companies")}</p>
            {searchVisible && <input className="search-input" type="text"  value={searchValue} onChange={onChangeSearch}  autoFocus/>}
            </div>
          </div>
          {state.rights === config.rights.root && (
            <FlatBtn type="plus" onClick={() => setShowCompanyCreate(true)} />
          )}
          {/*{<FlatBtn type="plus" onClick={() => setShowCompanyCreate(true)}/>}*/}
        </div>
        <div className="company__list">
          {loading && companies.length === 0 && (
            <div className="d-flex flex-column justify-content-center flex-grow-1">
              <Loader type="dot" />
            </div>
          )}
          {companies.length > 0 && (
            <List
              type={"companies"}
              list={companies}
              id={"companiesListID"}
              scrollHandler={() => console.log()}
              renderCondition={companies.length > 0}
              listItemProps={{
                to: state.rights < config.rights.tips ? "/company/" : false,
                rightIcon: state.rights < config.rights.tips ? <ImgEye /> : "",
              }}
              hasMore={hasMore}
            />
          )}
          <LoadMore
            renderCondition={companies.length > 0 && hasMore}
            clickHandler={clickHandler}
            loadingCondition={loading}
            buttonText={t("loadMore")}
            fullListText={""}
          />
        </div>
      </div>
      <Modal
        portalClassName="left-slide-window"
        isOpen={showMenu}
        contentLabel="Menu"
        closeTimeoutMS={300}
        className="admin-modal"
        overlayClassName="admin-modal__overlay"
      >
        <LeftMenu onClose={() => setShowMenu(false)} />
      </Modal>
      <Modal
        portalClassName="left-slide-window"
        isOpen={showCompanyCreate}
        contentLabel="CompanyCreate"
        closeTimeoutMS={300}
        className="admin-modal"
        overlayClassName="admin-modal__overlay"
      >
        <CompanyCreate onClose={() => setShowCompanyCreate(false)} />
      </Modal>
    </div>
  );
}
