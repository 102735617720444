import axios from 'axios';
import config from '../../config';
import Base from './Base';



export default class API extends Base {
    async get(method, params) {
        const promiseResp = fetch(config.apiUrl + '/' + method + "?" + new URLSearchParams(params).toString(), {
            method: 'GET',
            headers: {...this.headers, Authorization: 'Bearer ' + this.state.token},
        });
        
        return this.wrapResponse(promiseResp);
    }

    async post(method, params) {
        const promiseResp = fetch(config.apiUrl + '/' + method, {
            method: 'POST',
            headers: {...this.headers, Authorization: 'Bearer ' + this.state.token},
            body: JSON.stringify({...params}),
        });
        return this.wrapResponse(promiseResp);
    }
    async postFile(method, body) {
        const promiseResp = axios.post(config.apiUrl + '/' + method , body, {headers: {...this.headers, Authorization: 'Bearer ' + this.state.token, 'Content-Type': 'multipart/form-data' }});
        return promiseResp
    }

}
