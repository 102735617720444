import React, {useCallback, useEffect, useState} from 'react';

import './ProfileEdit.scss';

import useApi from "../../lib/hooks/useAPI";

import FlatBtn from "../common/FlatBtn";
import ActionButton from "../common/ActionButton";
import Loader from '../common/Loader'

import {ImgInput, PhoneInput} from "../common/ImgInput";
import {ReactComponent as ImgSuccess} from "../common/img/success_circle.svg";
import {ReactComponent as ImgProfile} from "../common/img/profile.svg";
import { ReactComponent as ImgPhone } from "../common/img/phone.svg";

import {useTranslation} from "react-i18next";

export default function ProfileEdit({user, onClose}) {

    const api = useApi();

    const [name, setName] = useState(user.name);
    const [secondName, setSecondName] = useState(user.secondName);
    const [surname, setSurname] = useState(user.surname);
    const [subscription, setSubscription] = useState(user.subscription);
    const [phone, setPhone] = useState(user.phone)
    const [isChangePhone, setIsChangePhone] = useState(false)
    const [existsPhone, setExistsPhone] = useState(true)
    const [loader, setLoader] = useState(false)

    const [showSuccess, setShowSuccess] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);

    const {t} = useTranslation();

    const userUpdate = async () => {
        setSaveLoader(true);
        const res = await api.post("api/v1/user.update", {
            userId: user.id,
            name: name,
            secondName: secondName,
            surname: surname,
            subscription: subscription,
            phone: isChangePhone ? phone : ""
        });

        setSaveLoader(false)
        if (!res.success) {
            return;
        }
        setShowSuccess(true);
        setTimeout(() => window.location.reload(), 1000);
    }

    const existsPhoneNumber = async () => {
        setLoader(true)
        const res = await api.get("api/v1/user.existsPhone", {phone: phone})
        if (!res.success) {
            return
        }
        if (user.phone != phone )  {
            setExistsPhone(res.data.exists)
        } else {
            setExistsPhone(false)
        }
        setLoader(false)
    }

    useEffect(() => {
        if (localStorage.getItem("rights") == 1) {
            setIsChangePhone(true)
        }
        setExistsPhone(false)
    }, [])

    const onPhoneChange = (val) => {
        setPhone("+" + val.replace(/\D/g, ""))
    }   

    useEffect(() => {
        if (phone.length > 11 && isChangePhone) {
            existsPhoneNumber()
        }
    }, [phone])

    return (
        <div className="admin central noselect modal-padding">
            <div className="app-container admin__app-container">
                <div className="admin__header">
                    <FlatBtn type="prev" onClick={() => onClose()}/>
                    <p className="header-text">{t("editCompanyData")}</p>
                </div>
                <div className="admin__center">
                    {!showSuccess &&
                        <div className="profile-edit">
                            <div className="profile-edit__item">
                                <ImgInput
                                    placeholder={t("lastName")}
                                    field={surname}
                                    onChange={e => setSurname(e.target.value)}
                                    image={<ImgProfile/>}
                                />
                            </div>
                            <div className="profile-edit__item">
                                <ImgInput
                                    placeholder={t("name")}
                                    field={name}
                                    onChange={e => setName(e.target.value)}
                                    image={<ImgProfile/>}
                                />
                            </div>
                            <div className="profile-edit__item">
                                <ImgInput
                                    placeholder={t("surname")}
                                    field={secondName}
                                    onChange={e => setSecondName(e.target.value)}
                                    imageType="doc"
                                />
                            </div>
                            <div className="profile-edit__item">
                                <ImgInput
                                    placeholder={t("imSavingUpFor")}
                                    field={subscription}
                                    onChange={e => setSubscription(e.target.value)}
                                    imageType="doc"
                                />
                            </div>
                            {isChangePhone && <div className="profile-edit__item">
                                <PhoneInput 
                                    image={<ImgPhone />}
                                    placeholder={t("phoneNumber")}
                                    autoComplete="off"
                                    value={phone}
                                    disabled={localStorage.getItem("rights") != 1}
                                    onChange={e => onPhoneChange(e.currentTarget.value)}
                                />
                            </div>}
                        </div>
                    }
                    {isChangePhone &&  existsPhone && <div style={{marginTop: 15}}>
                        {loader ? <Loader type={"dot"}/>  : <p className='red-text'>Пользователь с таким номером телефона существует</p>}
                    </div>}
                    {showSuccess &&
                        <div className="circle-success flex-grow-1">
                            <ImgSuccess/>
                            <p className="circle-success__text success">{t("dataUpdated")}</p>
                        </div>
                    }
                </div>
                <div className="admin__footer">
                    {!showSuccess &&
                        <ActionButton onClick={(e) => userUpdate(e)} text={t("save")} disabled={existsPhone} loader={saveLoader}/>}
                </div>
            </div>
        </div>
    );
}
