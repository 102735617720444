import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";

import "./ManagersList.scss";

import useApi from "../../../lib/hooks/useAPI";
import FlatBtn from "../../common/FlatBtn";

import { ReactComponent as ImgUsersGroup } from "../../common/img/users_group.svg";
import Loader from "../../common/Loader";
import useNotify from "../../../lib/hooks/Notify";
import Profile from "../Profile";
import { useTranslation } from "react-i18next";
import ActionButton from "../../common/ActionButton";
import { ManagerAdd } from "../ManagerAdd";
import {Employee} from "../../common/Employee";

import { ReactComponent as ImgBasket } from "../../common/img/basket.svg";

export const ManagersList = () => {
  const api = useApi();

  const notify = useNotify();

  const { companyId } = useParams();

  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddManager, setShowAddManager] = useState(false);
  const [userId, setUserId] = useState("");
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [managerNumbers, setManagerNumbers] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const { t } = useTranslation();

  const getManagers = async () => {
    setLoading(true);
    const res = await api.get("api/v1/company.getManagers", { companyId: companyId });
    if (!res.success || !res.data.managers) {
      return;
    }
    setManagerNumbers(res.data.managers.map((u) => u.number));
    setManagers(res.data.managers);
    setLoading(false);
  };

  const updateManagers = async (managerNumbers) => {
    await api.post("api/v1/company.updateManagers", {
      companyId: companyId,
      managerNumbers: managerNumbers,
    });
  };

  const companyDeleteManager = async (num) => {
    setLoading(true);
    const numsFiltered = managerNumbers.filter((mp) => mp !== num);
    updateManagers(numsFiltered).then(() => setIsUpdate(true));
    setLoading(false);

    notify({ type: "success", title: t("employeeRemoved"), description: "ok" });
  };

  const companyAddManager = async (numbers) => {
    const nums = [...managerNumbers, ...numbers];
    await updateManagers(nums);
  };

  useEffect(() => {
    getManagers().then(() => setIsUpdate(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading && isUpdate) {
      getManagers().then(() => setIsUpdate(false));
    }
  }, [isUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkButtonDisableByQuantity = () => {
    return managerNumbers.length >= 5;
  };


  const onCloseHandler = () => {
    setShowAddManager(false);
    setIsUpdate(true);
  };

  return (
    <div className="admin central noselect">
      <div className="app-container admin__app-container">
        <div className="admin__header">
          <Link to={"/company/" + companyId}>
            <FlatBtn type="prev" />
          </Link>
          <p className="header-text">
            {`${t("managers")} ${
              managers && managers.length > 0
                ? "(" + managers.length + ` ${t("per")})`
                : ""
            }`}
          </p>
        </div>
        <div className="admin__center">
          {loading && (
            <div className="d-flex flex-column justify-content-center flex-grow-1">
              <Loader type="dot" />
            </div>
          )}
          {!loading && managers.length === 0 && (
            <div className="employee-list__empty">
              <div className="employee-list__users-empty-img">
                <ImgUsersGroup />
              </div>
              <p className="employee-list__users-empty-text">
                {t("youDontHaveEmployeesYet")} <br />{" "}
                {t("youCanAddNewEmployee")}{" "}
              </p>
            </div>
          )}
          {!loading && managers.length > 0 && (
            <div className="employee-list__items">
              {managers.map((emp) => 
              <Employee
              key={emp.id}
              emp={emp}
              onClickItem={() => {
                setUserId(emp.id);
                setShowUserProfile(true);
              }}
              onRightLoader={loading}
              onClickRightIcon={() => companyDeleteManager(emp.number)}
              rightIcon={<ImgBasket/>}
            />
            //   (
            //     <div key={emp.id} className="employee-list__item">
            //       <ImageItem
            //         onClickItem={() => {
            //           setUserId(emp.id);
            //           setShowUserProfile(true);
            //         }}
            //         avatar={
            //           emp.photo ? (
            //             <AvatarSmall photo={emp.photo} />
            //           ) : (
            //             <ImgAvatarEmpty />
            //           )
            //         }
            //         text={emp.name + " " + emp.surname}
            //         rightIcon={
            //           !loading ? <ImgBasket /> : <Loader type="circle" />
            //         }
            //         onClickRightIcon={() => {
            //           companyDeleteManager(emp.phone).then(() => getManagers());
            //         }}
            //       />
            //     </div>
            //   )
              )}
            </div>
          )}
        </div>
        {!loading && (
          <ActionButton
            onClick={() => {
              setShowAddManager(true);
            }}
            text={t("addManager")}
            disabled={checkButtonDisableByQuantity()}
          />
        )}
      </div>
      <Modal
        portalClassName="left-slide-window"
        isOpen={showAddManager}
        contentLabel="EmployeeAdd"
        closeTimeoutMS={300}
        className="admin-modal"
        overlayClassName="admin-modal__overlay"
      >
        <ManagerAdd
          companyUpdateManagers={companyAddManager}
          onClose={onCloseHandler}
        />
      </Modal>
      <Modal
        portalClassName="left-slide-window"
        isOpen={showUserProfile}
        contentLabel="Profile"
        closeTimeoutMS={300}
        className="admin-modal"
        overlayClassName="admin-modal__overlay"
      >
        <Profile userId={userId} onClose={() => setShowUserProfile(false)} />
      </Modal>
    </div>
  );
};
